import {
  Album,
  Artist,
  CommunityComment,
  CommunityPost,
  LabEvent,
  LabLiveTraining,
  Setlist,
  Song,
  StoreItemTypeEnum,
  User
} from '../__generated__/graphql'

const albumPath = (album: Album): string => {
  return `${albumsPath(album.primaryArtist)}/${album.id}`
}

const albumsPath = (artist: Artist): string => {
  return `/artists/${artist.id}/albums`
}

const artistsPath = (params: any = {}): string => {
  const query = new URLSearchParams(params).toString()
  return query.length > 0 ? `/artists?${query.toString()}` : `/artists`
}

const artistPath = (artist: Artist): string => {
  return `/artists/${artist.id}`
}
const artistSongsPath = (artist: Artist): string => {
  return `${artistPath(artist)}/songs`
}

const songPath = (song: Pick<Song, 'id' | 'slug'>): string => {
  if (!!song.slug) {
    return `/songs/${song.slug}`
  } else {
    return `/songs/${song.id}`
  }
}

const songsPath = (params: any = {}): string => {
  const query = new URLSearchParams(params).toString()
  return query.length > 0 ? `/songs?${query.toString()}` : `/songs`
}

const downloadSetlistEventsPath = (setlist: Setlist) => {
  return `${process.env.NEXT_PUBLIC_OLD_URL}/setlists/${setlist.id}/download_schedule`
}
const downloadEventSchedulePath = (event: LabEvent) => {
  return `${process.env.NEXT_PUBLIC_OLD_URL}/lab_events/${event.id}/download_schedule`
}
const downloadLiveTrainingSchedulePath = (liveTraining: LabLiveTraining) => {
  return `${process.env.NEXT_PUBLIC_OLD_URL}/lab_live_trainings/${liveTraining.id}/download_schedule`
}

const storeUrl = () => {
  return '/store'
}

const storeSongsPath = (
  params: { q?: string; orderBy?: string; favorite?: string } = {
    q: '',
    orderBy: '',
    favorite: 'false'
  }
): string => {
  const query = new URLSearchParams(params).toString()
  const url =
    query.length > 0 ? `${storeUrl()}/songs?${query}` : `${storeUrl()}/songs`
  return url
}

const storeSongPath = (
  song: Pick<Song, 'id'>,
  tab?: StoreItemTypeEnum
): string => {
  return `/store/songs/${song.id}?tab=${tab || 'multi_tracks'}`
}

const topMultitracksPath = () => `${storeUrl()}/songs?sort=popular`

const topKeysPath = () => `${storeUrl()}/songs`

const wistiaVideoPath = (path: string): string => {
  return `https://home.wistia.com/medias/${path}`
}

const labPath = () => '/lab_plus'

const labChatPath = () => '/lab_plus/chat_assist'

const labPurchasePath = () => labPath() + '/purchase'

const labDealsPath = () => labPath() + '/deals'

const labTrainingPath = () => labPath() + '/training'

const labEventsPath = () => labTrainingPath() + '/events'

const labEventPath = (eventId: number) => labEventsPath() + '/' + eventId

const labCoursesPath = () => labTrainingPath() + '/courses'

const labBookmarksPath = () => labTrainingPath() + '/bookmarks'

const labCoursePath = (courseId: number) => labCoursesPath() + '/' + courseId

const labLiveTrainingsPath = () => labTrainingPath() + '/live_training'

const labLiveTrainingPath = (liveTrainingId: number) =>
  labLiveTrainingsPath() + '/' + liveTrainingId

const labCommunityPath = () => labPath() + '/community'

const labCommunityTopicPath = (topicId: number): string =>
  labCommunityPath() + '/' + 'topics/' + topicId

const labCommunityPostPath = (post: CommunityPost): string =>
  labCommunityTopicPath(post.category.id) + '/' + 'posts/' + post.id

const labCommunityCommentPath = (
  post: CommunityPost,
  comment: CommunityComment
): string => labCommunityPostPath(post) + '#' + `comment-${comment.id}`

const labCommunityMembersPath = (): string =>
  labCommunityPath() + '/' + 'members'

const labCommunityMemberPath = (member: User): string =>
  labCommunityMembersPath() + '/' + member?.id

const labEmailNotificationsPath = (): string =>
  labCommunityPath() + '/' + 'email_notifications'

export {
  songPath,
  artistPath,
  artistSongsPath,
  artistsPath,
  albumPath,
  albumsPath,
  songsPath,
  downloadSetlistEventsPath,
  downloadEventSchedulePath,
  storeUrl,
  storeSongsPath,
  storeSongPath,
  topMultitracksPath,
  topKeysPath,
  wistiaVideoPath,
  labPath,
  labChatPath,
  labPurchasePath,
  labDealsPath,
  labTrainingPath,
  labEventsPath,
  labEventPath,
  labCoursesPath,
  labCoursePath,
  labLiveTrainingsPath,
  labLiveTrainingPath,
  labCommunityPath,
  labCommunityTopicPath,
  labCommunityPostPath,
  labCommunityCommentPath,
  labCommunityMembersPath,
  labCommunityMemberPath,
  labEmailNotificationsPath,
  downloadLiveTrainingSchedulePath,
  labBookmarksPath
}
